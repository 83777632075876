.projectCard {
	display: flex;
	flex-direction: column;
	align-items: center;

	&-image {
		width: 100%;
		margin-bottom: 30px;
		box-shadow: var(--box-shadow);
	}

	&-category {
		font-size: 12px;
		line-height: 15px;
		color: var(--darkestGray);
		margin-bottom: 10px;
		text-transform: capitalize;
	}

	&-title {
		font-weight: 600;
		font-size: 18px;
		margin-bottom: 15px;
	}

	&.small {
		align-items: flex-start;
		height: 100%;

		.projectCard-title {
			font-size: 14px;
			line-height: 20px;
			margin-bottom: 10px;
			order: 1;
		}

		.projectCard-image {
			margin-bottom: 20px;
			order: 0;
		}

		.projectCard-category {
			position: relative;
			order: 2;

			&::after {
				content: '';
				width: 100%;
				height: 1px;
				bottom: 0;
				left: 0;
				background-color: var(--darkestGray);
				position: absolute;
			}
		}
	}

	&-titleLink {
		color: inherit;
	}

	&-description {
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		margin-bottom: 35px;
	}
}
